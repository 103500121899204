// extracted by mini-css-extract-plugin
module.exports = {"navbar":"_2SD45fbzHpmv_94_yyKFw0","navOptions":"_1ApvL2rEW8BrmwlbvxSK_","navLink":"_3YwM97iybE_X9GWtWdeqEF","navbarLogo":"_28EfmKzqdJ5DySbnpp-eqf","addVoiceLink":"_1hAFd909DSYFMIwM8ZZLYG","addVoice":"tSMz4w8DSmkKVvMeWnUFK","navbarMenuBtn":"_3JBo8g9q3OE9EJrwpWz1wR","navbarMenuIcon":"_2ZhMj4s7-I2zm0uY0XhI69","navbarMenu":"_23RFNndrxZouOzcMhNc0LB"};;
    if (module.hot) {
      var injectCss = function injectCss(prev, href) {
        var link = prev.cloneNode();
        link.href = href;
        link.onload = function() {
          prev.parentNode.removeChild(prev);
        };
        prev.stale = true;
        prev.parentNode.insertBefore(link, prev);
      };
      module.hot.dispose(function() {
        window.__webpack_reload_css__ = true;
      });
      if (window.__webpack_reload_css__) {
        module.hot.__webpack_reload_css__ = false;
        console.log("[HMR] Reloading stylesheets...");
        var prefix = document.location.protocol + '//' + document.location.host;
        document
          .querySelectorAll("link[href][rel=stylesheet]")
          .forEach(function(link) {
            if (!link.href.match(prefix) || link.stale) return;
            injectCss(link, link.href.split("?")[0] + "?unix=1737933696113");
          });
      }
    }
  